import React from 'react';
import { MDXRemote, MDXRemoteSerializeResult } from 'next-mdx-remote';
import dynamic from 'next/dynamic';

import fromCMS from 'utils/fromCMS';
import isUrlAbsolute from 'utils/isUrlAbsolute';
import { isUrlExternal } from 'utils/isUrlExternal';
import Image from 'next/image';

import { StyledOrderedList, StyledUnorderedList } from './Marked.styles';

type MarkedProps = {
  children?: string | MDXRemoteSerializeResult | null;
};

const components = {
  a: ({ href, ...props }) => {
    if (isUrlExternal(href)) {
      return <a {...props} href={href} target="_blank" rel="noreferrer noopener nofollow" />;
    }

    return <a {...props} href={href} />;
  },
  code: dynamic(() => import('components/CodeBlock/CodeBlock').then((mod) => mod.CodeBlock)),
  img: ({ src, ...props }) => {
    if (isUrlAbsolute(src)) {
      return <img {...props} loading="lazy" decoding="async" />;
    }

    const baseSrc = fromCMS(src);

    return (
      <div style={{ width: '100%', minHeight: '100px', position: 'relative' }}>
        <Image {...props} src={baseSrc} loading="lazy" decoding="async" layout="fill" objectFit="contain" />
      </div>
    );
  },
  ol: StyledOrderedList,
  ul: StyledUnorderedList,
  YTVideo: dynamic(() =>
    import('components/LazyYouTubeVideoEmbed/LazyYouTubeVideoEmbed').then((mod) => mod.LazyYouTubeVideoEmbed)
  )
};

export const Marked = ({ children }: MarkedProps) => {
  if (!children) {
    return null;
  }

  // Escape hatch to display Terms and Conditions without serializing them on the frontend
  if (typeof children === 'string') {
    return <div dangerouslySetInnerHTML={{ __html: children }} />;
  }

  return <MDXRemote {...children} components={components} />;
};
